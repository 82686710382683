import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getDashboard(data) {
        return api.instance.get("/dashboard", {
            params: data
        });
    }
};