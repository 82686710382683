import dashboardService from "../../services";

export default {
    data() {
        return {
            hasSuperAdmin: false,
            random: `images/lifely/partner-reward-points.png?reward_image=${Math.random()}`,
            partnerTypeRewardPercentage: 0,
            homeLink: false,
            partners: [],
            hasPartner: this.$store.state.user.user_type == "partner" ? true : false,
            filter: {
                user_id: "",
                country_id: this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()
            },
            user: null,
            users: {},
            data: {},
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            graphData: { labels: [], datasets: [ { label: '', data: [], backgroundColor: '#99f' } ] },
            orderGraphData: {},
            revenueGraphData: {},
            customerGraphData: {},
            graphOptions: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        stacked: true,
                        ticks: { display: true, }
                    },
                    y: {
                        stacked: true,
                        ticks: { display: true, precision: 0 }
                    }
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    }
                },
            }
        }
    },
    watch: {
        'user': function (value) {
            this.filter.user_id = value;
            this.getDashboard();
        },
        'country_id': function () {
            this.$router.replace({ query: { country: this.country_id } });
            this.filter.country_id = this.country_id;
            this.getDashboard();
        }
    },
    mounted() {

        this.getDashboard();
        if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
            this.filter.country_id = this.country_id;

        }
        if ('partnerType' in this.$store.state.user && this.$store.state.user.user_type != "super_admin" && this.$store.state.user.partnerType.reward_percentage) {
            this.partnerTypeRewardPercentage = this.$store.state.user.partnerType.reward_percentage;
        }
    },
    methods: {
        resetGraphData() {
            this.graphData = { labels: [], datasets: [ { label: '', data: [], backgroundColor: '#99f' } ] };
        },
        getDashboard() {
            this.showLoader(true);
            dashboardService.getDashboard(this.filter).then(response => {
                this.hideLoader();
                this.data = response.data;
                if (this.$store.state.user.user_type == "partner_admin") {
                    this.hasPartner = this.filter.user_id;
                    if (!this.filter.user_id) {
                        if (this.data.users) {
                            this.users = this.data.users ? this.data.users : {};
                        }
                    }
                }
                if (this.data.referral_link) {
                    this.data.referral_link = this.data.referral_link + "?medium=copy"
                }
                if (this.data.orders) {
                    this.resetGraphData();
                    this.graphData.labels = this.data.orders.months;
                    this.graphData.datasets = this.data.orders.datasets;
                    this.orderGraphData = this.graphData;
                }
                if (this.data.revenue) {
                    this.resetGraphData();
                    this.graphData.labels = this.data.revenue.months;
                    this.graphData.datasets = this.data.revenue.datasets;
                    this.revenueGraphData = this.graphData;
                }
                if (this.data.customers) {
                    this.resetGraphData();
                    this.graphData.labels = this.data.customers.months;
                    this.graphData.datasets = this.data.customers.datasets;
                    this.customerGraphData = this.graphData;
                }
            })
        },
        // doCopy: function () {
        //     let selectEl = document.getElementsByClassName('copyLinkInput')[0];
        //     selectEl.select();
        //     document.execCommand("copy");
        //     this.$popper.success('Referral Link Copied');
        // }
    }
}