<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fas fa-home font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Dashboard</h1>
    <router-link :to="{name: 'notification-home'}" class="header-icon header-icon-2">
        <i class="fas fa-bell font-15 position-relative"></i>
    </router-link>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<app-footer />
<div class="page-title-clear"></div>

<div class="page-content ">
    <!-- <div class="mb-2 d-flex justify-content-center">
        <router-link :to="{'name': 'order-create'}"
            class="btn  btn-icon font-600 btn-lg bg-green-light rounded-sm scale-box mt-2 me-2">
            <i class="fa fa-shopping-cart"></i>
            Online Order Now
        </router-link>
    </div> -->
    <!-- <div class="mb-2 d-flex justify-content-center" v-if="data.referral_link">
        <input type="text" class="form-control copyLinkInput hide" :value="data.referral_link"
            style="opacity: 0;position: absolute;">
        <button class="btn btn-icon font-600 btn-m bg-blue-light rounded-sm scale-box mt-2 me-2" data-menu="shareMenu"
            @click="doCopy">
            <i class="fa fa-share-alt"></i>
            Share Referral Link
        </button>
    </div> -->
    <div v-if="$store.state.user.user_type=='partner_admin'">
        <form-control class="validate-field input-style-active mt-2">
            <app-select name="state" placeholder="Partner Filter" :options="users" value-prop="id" v-model="user">
                <template v-slot:default="slotProps">
                    {{ slotProps.item.first_name }} {{ slotProps.item.last_name }}
                </template>
            </app-select>
        </form-control>
        <div class="card" v-show="!hasPartner">
            <div class="card-body">
                <div class="d-flex align-items-start justify-content-between">
                    <h4 class="card-title">Partners Revenue</h4>
                </div>
                <p class="text-dark mb-2">Total Revenue in Last month</p>
                <div class="d-flex flex-column justify-content-center">
                    <h1 class="font-weight-bold text-dark">{{ data.partnerAdminRevenue ?
                        data.partnerAdminRevenue.last_month_count : '0' }}</h1>
                    <div class="d-flex align-items-baseline flex-wrap">
                        <div class="d-flex"
                            v-if="(data.partnerAdminRevenue ? data.partnerAdminRevenue.growthLoss : '0') >0 ">
                            <h5 class=" font-weight-normal text-success">{{data.partnerAdminRevenue.growthLoss}}%</h5>
                            <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                        </div>
                        <div class="d-flex"
                            v-if="(data.partnerAdminRevenue ? data.partnerAdminRevenue.growthLoss : '0') < 0 ">
                            <h5 class=" font-weight-normal text-danger">
                                {{Math.abs(data.partnerAdminRevenue.growthLoss)}}%</h5>
                            <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                        </div>
                        <h5 class=" font-weight-normal me-2"
                            v-if="(data.partnerAdminRevenue ? data.partnerAdminRevenue.growthLoss : '0') == 0 ">0%</h5>
                        <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark">in last month</h5>
                    </div>
                </div>
            </div>
            <div>
                <app-chart height="200" id="partner-revenue-chart" type="bar" :data="revenueGraphData"
                    :options="graphOptions"></app-chart>
            </div>
        </div>
    </div>







    <!------------- PARTNER DASHBOARD STATISTICCOUNT START ------------->

    <div class="partner-detail" v-if="hasPartner">
        <div class="card card-style" v-if="partnerTypeRewardPercentage > 0">
            <a href="/orders/create" class="rewardInfoBanner">
                <img class="reward-point-img" loading="lazy" :src="random"
                    alt="">
            </a>
        </div>

        <div class="card card-style rounded-s shadow-l"
            :class="[ !(partnerTypeRewardPercentage > 0) ? 'mt-5': 'mt-0' ]">
            <a href="/orders/create" class="btn primary-alt-light-bg letterSpacing">
                Order Now
            </a>
        </div>
    </div>
    <!-- <div class="partner-detail" v-show="hasPartner">
        <div class="content mt-5">
            <div class="row justify-content-evenly text-center">
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'passbook-statements', params:{'userId':user}}"
                        class="card close-menu statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-chart-line icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic ? data.statistic.balance : '-' }}
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Wallet Balance</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'passbook-statements', params:{'userId':user}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-money-check-alt icon-dash"></i>
                                <h3 class="card-title font-30 mt-2 ">
                                    {{ data.statistic ? data.statistic.total_earning : '-' }}
                                </h3>
                            </div>
                            <p class="card-text font-16 font-600">Total Earning</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link to="#"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-money-bill-alt  icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">
                                    {{ data.statistic ? data.statistic.month_earning : '-' }}
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Month Earning</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link to="#"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-users  icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">
                                    0
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Refered Customers</p>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link to="#"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-users  icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">
                                    0
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Converted Customers</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'order-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-users  icon-dash"></i>
                                <h3 class="card-title font-35 mt-2">
                                    0
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Business score</p>
                        </div>
                    </router-link>
                </div>
            </div> -->

    <!------------- PARTNER ORDER CHART START ------------->

    <!-- <div class="card pb-2 px-0 mx-1 chartContainer">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h1 class="card-title font-20 mb-0 p-0 chartTitle">
                            <i class="fas fa-shopping-cart me-2"></i>
                            Orders
                        </h1>
                        <div class="orderChartStatisticCountNumberContainer">
                            <h1 class="font-weight-bold font-25 mb-0 p-0 ">
                                {{ data.orders ? data.orders.last_month_count : '0' }}
                            </h1>
                        </div>
                    </div>
                    <p class="mb-2 chartSubTitle">( Total Orders in Last month )</p>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex align-items-baseline flex-wrap">
                            <div class="d-flex" v-if="(data.orders ? data.orders.growthLoss : '0') >0 ">
                                <h5 class=" font-weight-normal text-success">{{Math.abs(data.orders.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <div class="d-flex" v-if="(data.orders ? data.orders.growthLoss : '0') < 0 ">
                                <h5 class=" font-weight-normal text-danger">{{Math.abs(data.orders.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <h5 class=" font-weight-normal me-2 chartGrowthPercentage"
                                v-if="(data.orders ? data.orders.growthLoss : '0') == 0 ">
                                0%
                            </h5>
                            <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark chartGrowthPercentage">
                                in last month
                            </h5>
                        </div>
                    </div>
                </div>
                <div>
                    <app-chart height="200" id="order-chart" type="bar" :data="orderGraphData" :options="graphOptions">
                    </app-chart>
                </div>
            </div> -->
    <!------------- PARTNER ORDER CHART END ------------->

    <!------------- PARTNER REVENUE CHART START ------------->
    <!-- <div class="card pb-2 px-0 mx-1 chartContainer">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h1 class="card-title font-20 mb-0 p-0 chartTitle">
                            <i class="fas fa-coins me-2"></i>
                            Revenue
                        </h1>

                        <div class="revenueChartStatisticCountNumberContainer">
                            <h1 class="font-weight-bold font-25 mb-0 p-0 ">
                                {{ data.revenue ? data.revenue.last_month_count : '0' }}
                            </h1>
                        </div>
                    </div>
                    <p class="mb-2 chartSubTitle">( Total Revenue in Last month )</p>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex align-items-baseline flex-wrap">
                            <div class="d-flex" v-if="(data.revenue ? data.revenue.growthLoss : '0') >0 ">
                                <h5 class=" font-weight-normal text-success">{{Math.abs(data.revenue.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <div class="d-flex" v-if="(data.revenue ? data.revenue.growthLoss : '0') < 0 ">
                                <h5 class=" font-weight-normal text-danger">{{ Math.abs(data.revenue.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <h5 class=" font-weight-normal me-2 chartGrowthPercentage"
                                v-if="(data.revenue ? data.revenue.growthLoss : '0') == 0 ">0%
                            </h5>
                            <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark chartGrowthPercentage">in
                                last month</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <app-chart height="200" id="revenue-chart" type="bar" :data="revenueGraphData"
                        :options="graphOptions">
                    </app-chart>
                </div>
            </div> -->
    <!------------- PARTNER REVENUE CHART END ------------->
    <!-- </div>
    </div> -->
    <!------------- PARTNER DASHBOARD STATISTICCOUNT END ------------->





    <div v-if="$store.state.user.user_type=='cluster_admin' || $store.state.user.user_type=='super_admin'">
        <div class="content mt-5">

            <!------------- CLUSTERADMIN DASHBOARD STATISTICCOUNT START ------------->

            <div class="row justify-content-evenly text-center" v-if="$store.state.user.user_type=='cluster_admin'">
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link to="#" class="card close-menu gradient-green">
                        <div class="card-body mt-3">
                            <i class="fas fa-city  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ? data.statistic.states :
                                '-' }}</h3>
                            <p class=" card-text font-16 font-800 color-theme">States</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link to="#" class="card close-menu gradient-mint">
                        <div class="card-body mt-3">
                            <i class="fas fa-building  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ? data.statistic.cities :
                                '-' }}</h3>
                            <p class="card-text font-16 font-800 color-theme">Cities</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link :to="{name: 'partners-home'}" class="card close-menu gradient-teal">
                        <div class="card-body mt-3">
                            <i class="fas fa-cart-plus  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ?
                                data.statistic.active_partners : '-' }}</h3>
                            <p class="card-text font-16 font-800 color-theme">Active Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link :to="{name: 'partners-home'}" class="card close-menu gradient-brown">
                        <div class="card-body mt-3">
                            <i class="fas fa-shopping-cart  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ?
                                data.statistic.inActive_partners : '-' }}</h3>
                            <p class="card-text font-16 font-800 color-theme">In Active Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link :to="{name: 'customer-home'}" class="card close-menu gradient-aqua">
                        <div class="card-body mt-3">
                            <i class="fas fa-users  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ? data.statistic.customers :
                                '-' }}</h3>
                            <p class="card-text font-16 font-800 color-theme">Customers</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                    <router-link :to="{name: 'order-home'}" class="card close-menu gradient-aqua">
                        <div class="card-body mt-3">
                            <i class="fas fa-users  text-dark icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 text-dark">{{ data.statistic ? data.statistic.orders :
                                '-' }}</h3>
                            <p class="card-text font-16 font-800 color-theme">Orders</p>
                        </div>
                    </router-link>
                </div>
            </div>

            <!------------- CLUSTERADMIN DASHBOARD STATISTICCOUNT END ------------->



            <!------------- SUPER ADMIN DASHBOARD STATISTICCOUNT START ------------->

            <div class="row justify-content-evenly text-center" v-if="$store.state.user.user_type=='super_admin'">
                <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partners-home', query:{'country': country_id}}"
                        class="card close-menu statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-chart-line icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.active_partners ? data.statistic.active_partners : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Active Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partners-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-hands-helping  icon-dash"></i>
                                <h3 class="card-title font-30 mt-2 ">{{ data.statistic &&
                                    data.statistic.inActive_partners ? data.statistic.inActive_partners : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 font-600">InActive Partners</p>
                        </div>
                    </router-link>
                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partners-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-user icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.approved_partners ? data.statistic.approved_partners : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Approved Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partners-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-user icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.disapproved_partners ? data.statistic.disapproved_partners : '0' }}
                                </h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Disapproved Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partners-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-user icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.pending_partners ? data.statistic.pending_partners : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Pending Partners</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'cluster-admin-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-user-shield icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.cluster_admin ? data.statistic.cluster_admin : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Cluster Admin</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'partner-admin-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-users-cog  icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic &&
                                    data.statistic.partner_admin ? data.statistic.partner_admin : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Partner Admin</p>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'customer-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-walking icon-dash"></i>
                                <h3 class="card-title font-30 mt-2">{{ data.statistic && data.statistic.customers
                                    ? data.statistic.customers : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Customers</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 statisticCountCardContainer">
                    <router-link :to="{name: 'order-home', query:{'country': country_id}}"
                        class="card close-menu  statisticCountCard">
                        <div class="card-body mt-3 d-flex flex-column justify-content-around">
                            <div class="d-flex justify-content-around align-items-center mb-3">
                                <i class="fas fa-shipping-fast  icon-dash"></i>
                                <h3 class="card-title font-35 mt-2">{{ data.statistic && data.statistic.orders ?
                                    data.statistic.orders : '0' }}</h3>
                            </div>
                            <p class="card-text font-16 letterSpacing font-600">Orders</p>
                        </div>
                    </router-link>
                </div>
            </div>

            <!------------- SUPER ADMIN DASHBOARD STATISTICCOUNT END ------------->


            <!------------- SUPER ADMIN DASHBOARD CUSTOMER CHART START ------------->

            <div class="card pb-2 px-0 mx-1 chartContainer">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h1 class="card-title font-20 mb-0 p-0 chartTitle">
                            <i class="fas fa-users me-2"></i>
                            Customers
                        </h1>
                        <div class="customerChartStatisticCountNumberContainer">
                            <h1 class="font-weight-bold font-25 mb-0 p-0 ">
                                {{ data.customers ? data.customers.last_month_count : '0' }}
                            </h1>
                        </div>
                    </div>
                    <p class="mb-2 chartSubTitle">( Total Orders in Last month )</p>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex align-items-baseline flex-wrap">
                            <div class="d-flex" v-if="(data.customers ? data.customers.growthLoss : '0') >0 ">
                                <h5 class=" font-weight-normal text-success">{{Math.abs(data.customers.growthLoss)}}%
                                </h5>
                                <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <div class="d-flex" v-if="(data.customers ? data.customers.growthLoss : '0') < 0 ">
                                <h5 class=" font-weight-normal text-danger">{{Math.abs(data.customers.growthLoss)}}%
                                </h5>
                                <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <h5 class=" font-weight-normal me-2 chartGrowthPercentage"
                                v-if="(data.customers ? data.customers.growthLoss : '0') == 0 ">0%
                            </h5>
                            <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark chartGrowthPercentage">in
                                last month</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <app-chart height="200" id="ca-customer-chart" type="bar" :data="customerGraphData"
                        :options="graphOptions">
                    </app-chart>
                </div>
            </div>

            <!------------- SUPER ADMIN DASHBOARD CUSTOMER CHART END ------------->


            <!------------- SUPER ADMIN DASHBOARD ORDERS CHART START ------------->

            <div class="card pb-2 px-0 mx-1 chartContainer">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h1 class="card-title font-20 mb-0 p-0 chartTitle">
                            <i class="fas fa-shopping-cart me-2"></i>
                            Orders
                        </h1>
                        <div class="orderChartStatisticCountNumberContainer">
                            <h1 class="font-weight-bold font-25 mb-0 p-0 ">{{ data.orders ? data.orders.last_month_count
                                : '0' }}</h1>
                        </div>
                    </div>
                    <p class="mb-2 chartSubTitle">( Total Orders in Last month )</p>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex align-items-baseline flex-wrap">
                            <div class="d-flex" v-if="(data.orders ? data.orders.growthLoss : '0') >0 ">
                                <h5 class=" font-weight-normal text-success">{{Math.abs(data.orders.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <div class="d-flex" v-if="(data.orders ? data.orders.growthLoss : '0') < 0 ">
                                <h5 class=" font-weight-normal text-danger">{{Math.abs(data.orders.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <h5 class=" font-weight-normal me-2 chartGrowthPercentage"
                                v-if="(data.orders ? data.orders.growthLoss : '0') == 0 ">0%
                            </h5>
                            <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark chartGrowthPercentage">in
                                last month</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <app-chart height="200" id="ca-order-chart" type="bar" :data="orderGraphData"
                        :options="graphOptions">
                    </app-chart>
                </div>
            </div>

            <!------------- SUPER ADMIN DASHBOARD ORDERS CHART END ------------->


            <!------------- SUPER ADMIN DASHBOARD REVENUE CHART START ------------->

            <div class="card pb-2 px-0 mx-1 chartContainer">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h1 class="card-title font-20 mb-0 p-0 chartTitle">
                            <i class="fas fa-coins me-2"></i>
                            Revenue
                        </h1>

                        <div class="revenueChartStatisticCountNumberContainer">
                            <h1 class="font-weight-bold font-25 mb-0 p-0 ">{{ data.revenue ?
                                data.revenue.last_month_count : '0' }}</h1>
                        </div>
                    </div>
                    <p class="mb-2 chartSubTitle">( Total Revenue in Last month )</p>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex align-items-baseline flex-wrap">
                            <div class="d-flex" v-if="(data.revenue ? data.revenue.growthLoss : '0') >0 ">
                                <h5 class=" font-weight-normal text-success">{{Math.abs(data.revenue.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-up text-success ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <div class="d-flex" v-if="(data.revenue ? data.revenue.growthLoss : '0') < 0 ">
                                <h5 class=" font-weight-normal text-danger">{{ Math.abs(data.revenue.growthLoss)}}%</h5>
                                <i class="fas fa-arrow-down text-danger ms-1 me-2 mt-0 font-20"></i>
                            </div>
                            <h5 class=" font-weight-normal me-2 chartGrowthPercentage"
                                v-if="(data.revenue ? data.revenue.growthLoss : '0') == 0 ">0%
                            </h5>
                            <h5 class="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal text-dark chartGrowthPercentage">in
                                last month</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <app-chart height="200" id="ca-revenue-chart" type="bar" :data="revenueGraphData"
                        :options="graphOptions">
                    </app-chart>
                </div>
            </div>

            <!------------- SUPER ADMIN DASHBOARD REVENUE CHART END ------------->


        </div>
    </div>

    <!-- <div class="page-details" v-if="$store.state.user.user_type == 'super_admin'">
        <div class="card card-style">
            <div class="content mb-0">
                <h1 class="text-center font-28 mb-4">Orders</h1>
                <div class="chart-container mb-4" style="width:100%; height:350px;">
                    <canvas class="graph" id="vertical-chart"></canvas>
                </div>
            </div>
        </div>

        <div class="card card-style">
            <div class="content mb-0">
                <h1 class="text-center font-28 mb-4">Payouts</h1>
                <div class="chart-container mb-2" style="width:100%; height:500px;">
                    <canvas class="graph" id="horizontal-chart"></canvas>
                </div>
            </div>
        </div>
        <div class="card card-style">
            <div class="content mb-0">
                <h1 class="text-center font-26 mb-4">Partner Joined</h1>
                <div class="chart-container" style="width:100%; height:350px;">
                    <canvas class="graph" id="line-chart"></canvas>
                </div>
            </div>
        </div>
        <div class="content mb-2">
            <div class="row">
                <div class="col-md-6">
                    <table class="table text-center shadow-l caption-top bg-white" style="overflow: hidden;">
                        <caption class="font-15 font-800 color-theme">Orders</caption>
                        <thead class="color-theme">
                            <tr>
                                <th scope="col">OrderId</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Created On</th>
                            </tr>
                        </thead>
                        <tbody class="text-secondary">
                            <tr>
                                <td scope="row">ORD-1</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">ORD-2</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">ORD-3</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table text-center shadow-l caption-top bg-white" style="overflow: hidden;">
                        <caption class="font-15 font-800 color-theme">Payouts</caption>
                        <thead class="color-theme">
                            <tr>
                                <th scope="col">PaymentId</th>
                                <th scope="col">Partner</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Created On</th>
                            </tr>
                        </thead>
                        <tbody class="text-secondary">
                            <tr>
                                <td scope="row">PMT-1</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">PMT-2</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">PMT-3</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="table text-center shadow-l caption-top bg-white" style="overflow: hidden;">
                        <caption class="font-15 font-800 color-theme">Recently Joined Partners</caption>
                        <thead class="color-theme">
                            <tr>
                                <th scope="col">PartnerId</th>
                                <th scope="col">Name</th>
                                <th scope="col">Created On</th>
                            </tr>
                        </thead>
                        <tbody class="text-secondary">
                            <tr>
                                <td scope="row">PTR-1</td>
                                <td>xyz</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">PTR-2</td>
                                <td>xyz</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">PTR-3</td>
                                <td>xyz</td>
                                <td>05/02/2020</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table text-center shadow-l caption-top bg-white" style="overflow: hidden;">
                        <caption class="font-15 font-800 color-theme">Commissions</caption>
                        <thead class="color-theme">
                            <tr>
                                <th scope="col">CommissionId</th>
                                <th scope="col">Partner</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Created On</th>
                            </tr>
                        </thead>
                        <tbody class="text-secondary">
                            <tr>
                                <td scope="row">CMS-1</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">CMS-2</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                            <tr>
                                <td scope="row">CMS-3</td>
                                <td>xyz</td>
                                <td>5000</td>
                                <td>05/02/2020</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->
</div>